<template>
  <base-icon-button-picture-as-pdf :disabled="disabled"
                                   @click="onClick"/>
</template>

<script>
  import BaseIconButtonPictureAsPdf from '@/components/baseComponents/baseIcons/BaseIconButtonPictureAsPdf';
  import { send_pdf_to_user } from '@/helpers/pdfDownloadFunctions';

  export default {
    name: 'ActionDownloadPdf',
    components: { BaseIconButtonPictureAsPdf },
    props: {
      proposal: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      onClick() {
        return this.$listeners.click || this.downloadPdf;
      },
      disabled() {
        return this.$attrs.disabled ?? !this.hasProperty(this.proposal, 'pdf_id');
      },
    },
    methods: {
      async downloadPdf() {
        if (this.hasProperty(this.proposal, 'pdf_id')) {
          const response = await this.axios.get(`/documents/download/${this.proposal.pdf_id}`);
          const { error } = response.data;
          if (error) {
            this.$notify({ type: 'error', title: 'PDF for this proposal doesn\'t exists' });
          } else if (this.hasProperty(response.data, 'msg')) {
            this.$notify({ type: 'error', title: response.data.msg });
          } else {
            send_pdf_to_user(response.request.responseURL);
          }
        }
      },
    },
  };
</script>

<style scoped>

</style>
